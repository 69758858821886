import React, { useState, ReactNode } from "react";
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import { Checkbox } from "./components/ui/checkbox";
import { Label } from "./components/ui/label";
import { RadioGroup, RadioGroupItem } from "./components/ui/radio-group";
import { Toaster } from "./components/ui/toaster";
import { toast } from "./components/ui/use-toast";
import CoverRed from "./images/red.png";
import CoverBlue from "./images/blue.png";
import CoverGreen from "./images/green.png";
import { Loader2 } from "lucide-react";

interface MessageBoxProps {
  children: ReactNode;
  color?: string;
}

const MessageBox: React.FC<MessageBoxProps> = ({
  children,
  color = "#E3F2FD",
}) => {
  const isHexColor = (color: string) => /^#[0-9A-Fa-f]{6}$/.test(color);

  const getColorStyles = (color: string) => {
    if (isHexColor(color)) {
      const borderColor = shadeColor(color, -30);
      const textColor = shadeColor(color, -60);
      return {
        backgroundColor: color,
        borderLeftColor: borderColor,
        color: textColor,
      };
    }
    return {};
  };

  const shadeColor = (color: string, percent: number) => {
    const num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  };

  const colorStyles = getColorStyles(color);

  return (
    <div className="p-4 mb-4 rounded-md border-l-4" style={colorStyles}>
      {children}
    </div>
  );
};

const BlogToEpubConverter = (props: any) => {
  const [savedUrl, setSavedUrl] = useState("");
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [reverse, setReverse] = useState(false);
  const [extractedLinks, setExtractedLinks] = useState<any[]>([]);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedCover, setSelectedCover] = useState("");

  const handleUrlSubmit = async () => {
    try {
      setIsExtracting(true);
      const response = await fetch("https://blogtoepub.com/api/extract_links", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });
      const data = await response.json();
      const links: string[] = data.links;
      const titles: string[] = data.titles;
      setExtractedLinks(
        links.map((link, i) => {
          return { id: i, title: titles[i], url: link, selected: true };
        })
      );
      setSelectedCover("red");
      setTitle(data.mainTitle);
      setSavedUrl(url);
    } catch {
      toast({
        title: "Error",
        description: "An error occurred.",
        duration: 5000,
        variant: "destructive",
      });
    }
    setIsExtracting(false);
  };

  const toggleLinkSelection = (id: any) => {
    setExtractedLinks(
      extractedLinks.map((link) =>
        link.id === id ? { ...link, selected: !link.selected } : link
      )
    );
  };

  const reverseOrder = () => {
    setExtractedLinks([...extractedLinks].reverse());
    setReverse(true);
  };

  const selectAll = () => {
    setExtractedLinks(
      extractedLinks.map((link) => {
        return { ...link, selected: true };
      })
    );
  };

  const handleSubmit = async () => {
    try {
      setIsGenerating(true)
      const response = await fetch("https://blogtoepub.com/api/submit_task", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url,
          title,
          exclude: extractedLinks
            .filter((link) => !link.selected)
            .map((link) => link.url),
          cover: selectedCover,
          reverse,
        }),
      });
      const data = await response.json()
      props.setTaskID(data.task_id)
    } catch {
      toast({
        title: "Error",
        description: "An error occurred.",
        duration: 5000,
        variant: "destructive",
      });
    }
    setIsGenerating(false)
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Blog to EPUB Converter</h1>
      <Toaster />
      <MessageBox color="#DEDEDA">
        <b>Instructions:</b> Enter the blog url and click "Extract Links" to choose and
        confirm the posts you want. Then, you can edit the title, select a cover
        style, and click "Generate EPUB" to create your book.
        <br />
        <br />
        The blog url should be a page where all the posts are linked e.g. {" "}
        <a href="https://paulgraham.com/articles.html" rel="noreferrer" target="_blank">
          <u>https://paulgraham.com/articles.html</u>
        </a>.
        <br/>
        Don't worry if there are other links present, we will intelligently select only the blog posts.
      </MessageBox>
      <div className="mb-4">
        <div className="mb-2">
          <Label htmlFor="url">
            Blog URL <span className="required">*</span>
          </Label>
        </div>
        <div className="flex">
          <Input
            id="url"
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://example.com/blog"
            className="mr-2"
          />
          <Button
            onClick={handleUrlSubmit}
            disabled={url.length === 0 || isExtracting}
          >
            {isExtracting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Extracting...
              </>
            ) : (
              "Extract Links"
            )}
          </Button>
        </div>
      </div>

      {extractedLinks.length > 0 && (
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-xl font-semibold">Extracted Links</h2>
            <div className="space-x-2">
              {" "}
              {/* TODO: maybe do exclude all option as well */}
              <Button onClick={selectAll}>Include All</Button>
              <Button onClick={reverseOrder}>Reverse Order</Button>
            </div>
          </div>
          <div
            className="border rounded-lg overflow-hidden"
            style={{ maxHeight: 300, overflowY: "scroll" }}
          >
            <div className="grid grid-cols-12 bg-gray-100 font-semibold">
              <div className="p-2 col-span-2">Include?</div>
              <div className="p-2 col-span-4">Title</div>
              <div className="p-2 col-span-6">URL</div>
            </div>
            {extractedLinks.map((link) => (
              <div key={link.id} className="grid grid-cols-12 border-t">
                <div className="p-2 col-span-2">
                  <Checkbox
                    checked={link.selected}
                    onCheckedChange={() => toggleLinkSelection(link.id)}
                  />
                </div>
                <div className="p-2 col-span-4">{link.title}</div>
                <div className="p-2 col-span-6">{link.url}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mb-4">
        <div className="mb-2">
          <Label htmlFor="title">
            Title <span className="required">*</span>
          </Label>
        </div>
        <Input
          id="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter EPUB title"
          disabled={extractedLinks.length === 0}
        />
      </div>

      <div className="mb-4">
        <div className="mb-2">
          <Label>
            Cover Style <span className="required">*</span>
          </Label>
        </div>
        <RadioGroup
          value={selectedCover}
          onValueChange={setSelectedCover}
          disabled={extractedLinks.length === 0}
        >
          <div className="flex space-x-8">
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="red" id="cover1" />
              <img
                src={CoverRed}
                alt="Red cover"
                className="w-32 h-48 object-cover"
              />
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="blue" id="cover2" />
              <img
                src={CoverBlue}
                alt="Blue cover"
                className="w-32 h-48 object-cover"
              />
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="green" id="cover3" />
              <img
                src={CoverGreen}
                alt="Green gover"
                className="w-32 h-48 object-cover"
              />
            </div>
          </div>
        </RadioGroup>
      </div>

      <Button
        onClick={handleSubmit}
        disabled={
          savedUrl.length === 0 ||
          extractedLinks.length === 0 ||
          title.length === 0 ||
          selectedCover.length === 0 ||
          isGenerating
        }
      >
        {isGenerating ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Generate EPUB
          </>
        ) : (
          "Generate EPUB"
        )}
      </Button>
    </div>
  );
};

export default BlogToEpubConverter;

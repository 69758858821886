import React, { useState, useEffect, useRef } from "react";
import { AlertCircle, Download, ChevronLeft } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "./components/ui/alert";
import { Button } from "./components/ui/button";
import { Toaster } from "./components/ui/toaster";
import { toast } from "./components/ui/use-toast";

const AlwaysScrollToBottom = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView();
    }
  });
  return <div ref={elementRef} />;
};

const TroubleshootingSteps = () => (
  <div className="mt-4">
    <h3 className="text-lg font-semibold mb-2">
      Troubleshooting Steps (if you can't open the file)
    </h3>
    <ul className="list-disc list-inside">
      <li>If you're using a Kindle, try uploading the EPUB file anyway.</li>
      <li>Use a different EPUB reader.</li>
      <li>
        Convert the EPUB to PDF using{" "}
        <a
          href="https://cloudconvert.com/epub-to-pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          CloudConvert
        </a>
        .
      </li>
      <li>Recreate the EPUB and select fewer links.</li>
    </ul>
  </div>
);

export const Dashboard = (props: any) => {
  const [percentages, setPercentages] = useState({
    "Successful Retrievals": "--",
    "Successful Renders": "--",
    Overall: "--",
  });
  const [messages, setMessages] = useState<any[]>([]);
  const [downloadLink, setDownloadLink] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (downloadLink !== "") {
        return;
      }
      try {
        const response = await fetch(
          `https://blogtoepub.com/api/task_status/${props.taskID}`
        );
        const data = await response.json();

        const messages = data.result.data.messages.map(
          (msg: any) => `${msg.stage} ${msg.status}: ${msg.link}`
        );
        setMessages(messages);

        const stats = data.result.data.stats;

        let retrievalSuccess: number | undefined = undefined;
        let renderSuccess: number | undefined = undefined;
        let overall: number | undefined = undefined;
        if (stats.retrieval) {
          retrievalSuccess =
            (100 * stats.retrieval.success) /
            (stats.retrieval.success + stats.retrieval.fail);
        }
        if (stats.render) {
          renderSuccess =
            (100 * stats.render.success) /
            (stats.render.success + stats.render.fail);
        }
        if (retrievalSuccess && renderSuccess) {
          overall = (renderSuccess * retrievalSuccess) / 100;
        }

        setPercentages({
          "Successful Retrievals": retrievalSuccess
            ? Math.round(retrievalSuccess).toString()
            : "--",
          "Successful Renders": renderSuccess
            ? Math.round(renderSuccess).toString()
            : "--",
          Overall: overall ? Math.round(overall).toString() : "--",
        });

        const link = data.result.link;

        if (link) {
          setDownloadLink(link);
          clearInterval(messageInterval);
        }
      } catch {
        toast({
          title: "Error",
          description: "An error occurred.",
          duration: 5000,
          variant: "destructive",
        });
        clearInterval(messageInterval);
      }
    };

    const messageInterval = setInterval(fetchData, 1000);

    return () => clearInterval(messageInterval);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="container mx-auto p-4">
      <Toaster />
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Blog to EPUB Converter</h1>
        <Button
          onClick={refreshPage}
          className="inline-flex items-center px-4 py-2"
        >
          <ChevronLeft className="mr-2" size={20} />
          Home
        </Button>
      </div>
      <div className="p-4 max-w-4xl mx-auto">
        <div className="grid grid-cols-3 gap-4 mb-4">
          {Object.entries(percentages).map(([key, value]) => (
            <div key={key} className="bg-white p-4 rounded-lg shadow">
              <h2 className="text-lg font-semibold mb-2">{key}</h2>
              <p className="text-3xl font-bold">{value}%</p>
            </div>
          ))}
        </div>

        <div className="bg-gray-900 text-white p-4 rounded-lg shadow mb-4">
          <h2 className="text-lg font-semibold mb-2">Terminal Output</h2>
          <div className="h-40 overflow-y-auto font-mono text-sm">
            <p>Starting job...</p>
            {messages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
            <AlwaysScrollToBottom />
          </div>
        </div>

        {downloadLink ? (
          <div>
            <a
              href={downloadLink}
              download
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              <Download className="mr-2" size={20} />
              Download File
            </a>
            <TroubleshootingSteps />
          </div>
        ) : (
          <Alert>
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Download not yet available</AlertTitle>
            <AlertDescription>
              Please wait for the file to be ready for download.
            </AlertDescription>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

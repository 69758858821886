import BlogToEpubConverter from './Converter';
import { useState } from 'react';
import './App.css'
import Dashboard from './Dashboard';

function App() {
  const [taskID, setTaskID] = useState<string | undefined>(undefined)

  return (
    <div className="component shadow-lg">
      {!taskID && <BlogToEpubConverter setTaskID={setTaskID} />}
      {taskID && <Dashboard taskID={taskID}/>}
    </div>
  );
}

export default App;
